import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import {
  Alert,
  AlertDetailItem,
  AlertDetailsList,
  AlertTitle,
} from "~/components/ui/alert";

//TODO: BPHMBLD-300 Error scenarios:
// 1.Failed login: Incorrect credentials. Please try again.
// 2.Too many incorrect login attempts: Your account is locked due to multiple incorrect login attempts.
//                                    Please reset your password to unlock.
// 3.Connectivity issue: Connection issue. Unable to log in. Please check your internet connection and try again.
// We need design for global errors

export const GlobalErrors = ({
  showFieldErrors,
  data,
}: {
  showFieldErrors?: boolean;
  data:
    | ({
        globalErrors?: string[];
        fieldErrors?: Record<string, string>;
      } & Record<string, unknown>)
    | undefined
    | null
    | unknown;
}) => {
  const fieldErrors: Record<string, string> =
    data &&
    typeof data === "object" &&
    "fieldErrors" in data &&
    typeof data?.fieldErrors === "object" &&
    (data?.fieldErrors as any);

  const globalErrors =
    data &&
    typeof data === "object" &&
    "globalErrors" in data &&
    Array.isArray(data.globalErrors) &&
    data.globalErrors?.length &&
    data.globalErrors.every((e): e is string => typeof e === "string")
      ? data?.globalErrors
      : undefined;

  if (
    (Array.isArray(globalErrors) && globalErrors?.length) ||
    (fieldErrors && Object.keys(fieldErrors).length && showFieldErrors)
  ) {
    return (
      <div className="my-6 flex w-full flex-col gap-3">
        {globalErrors && (
          <Alert variant="error">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>
              There were {globalErrors.length} errors with your submission
            </AlertTitle>
            <AlertDetailsList>
              {globalErrors.map((message, i) => (
                <AlertDetailItem key={i}>{message}</AlertDetailItem>
              ))}
            </AlertDetailsList>
          </Alert>
        )}

        {showFieldErrors &&
          fieldErrors &&
          Object.keys(fieldErrors).length > 0 && (
            <Alert variant="error">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>
                There were {Object.keys(fieldErrors).length} errors with your
                submission
              </AlertTitle>
              <AlertDetailsList>
                {Object.entries(fieldErrors).map(([field, message], i) => (
                  <AlertDetailItem key={i} name={field}>
                    {message}
                  </AlertDetailItem>
                ))}
              </AlertDetailsList>
            </Alert>
          )}
      </div>
    );
  }
  return null;
};
